/**
* Button Style
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

.button {
  width: 495px;
  height: 98px;

  background: #182C1A;
  transition: background-color 0.5s ease;
  box-shadow: 6px 7px 19px rgba(0, 0, 0, 0.35);
  border-radius: 12px;

  display: grid;
  place-items: center;

  margin-top: 60px;

  color: #fff;
  font-weight: 600;
  font-size: 40px;
  text-decoration: none;
}


.button:hover {
  background-color: rgba(24, 44, 26, 0.95)
}

/* MOBILE Section */
@media only screen and (max-width: 1080px) {
  .button {
    width: 70%;
    height: 70px;
    font-size: 25px;
  }
}
