.org-divs-info-app {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 2em;
  width: 75%;
  margin: 0 auto;
  margin-top: 5em;
  margin-bottom: 2em;
}

.org-divs-info-app img {
  width: 25em;
}

.info-img-cell {
  display: flex;
  justify-content: center;
}

.org-divs-info-app-texts {
  width: 85%;
}

.org-divs-info-app-texts h2 {
  font-weight: 600;
  font-size: 48px;
  color: #182C1A;
}

.org-divs-info-app-texts p {
  font-size: 32px;
  color: #646464;

}

.img-playstore {
  width: 25em !important;
  margin-top: 2em;
  cursor: pointer;
}


/* MOBILE Section */
@media only screen and (max-width: 1080px) {

  .org-divs-info-app {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    width: 75%;
    margin: 0 auto;
    margin-top: 5em;
    margin-bottom: 2em;
  }

  .org-divs-info-app .img-playstore {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    width: 17em !important;
    margin: 0 auto;
    margin-top: 5em;
    margin-bottom: 2em;
  }

  .org-divs-info-app img {
    width: 17em;
  }

  .org-divs-info-app-texts p {
    font-size: 20px;
    text-align: center;
    color: #646464;
  }

  .org-divs-info-app-texts h2 {
    font-weight: 600;
    font-size: 30px;
    color: #182C1A;
    text-align: center;
  }

}