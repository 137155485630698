/**
* Home Style
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

.home .text-container .about {
  font-size: 32px;
  color: #3D3D3D;

  width: 661px;
  text-align: justify;
  font-weight: 400;
  margin-bottom: 3em;
}

.home img {
  width: 20em;
  height: 20em;
 
}

.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  justify-content: center;
  align-content: center;
  height: 65vh;
  width: 80%;
}

.img-logo{
  display: flex;
  justify-content: center;

}



/* MOBILE Section */
@media only screen and (max-width: 1080px) {
  .home {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home img {
    width: 200px;
    height: 200px;
    margin-top: 4rem;
  }

  .text-container {
    margin-right: 0px;
  }

  .home .text-container .about {
    font-size: 20px;
    width: 80%;
  }

  header{
    margin-bottom: -3em;
  }


  .horizontal_divider {
    border-top: 2px solid #BBBBBB;
    height: 10px;
    width: 80%;
    margin-top: 0em;
}


  .home {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    justify-content: center;
    align-content: center;
    height: 65vh;
    width: 80%;
  }
  .img-logo{
    display: none;
  }



  
}