/**
* Avatar Style
* Created by Emmanuella Albuquerque on 2023/03/13.
*/

.avatar-container {
  display: grid;
  place-items: center;
}

.avatar-container p {
  color: #FFF;
  font-weight: 700;
  font-size: 20px;

  text-align: center;
}
