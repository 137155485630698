/**
* Divider Style
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

.horizontal_divider {
  border-top: 2px solid #BBBBBB;

  height: 10px;
  width: 80%;
}

.vertical_divider {
  border-left: 5px solid #D9D9D9;
  height: 437px;
}
