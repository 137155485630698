/**
* Sidebar Component
* Created by Emmanuella Albuquerque on 2023/03/12.
*/

.bm-item {
  color: #182C1A;
  text-align: center;

  margin-bottom: 10px;
  text-decoration: none;
  padding: 1rem;
}

nav ul li a{
  color: #182C1A;
  transition: all 0.5s;
}
nav ul li a:hover{
  color: #509c4f;
 
}



/* MOBILE HEADER */
@media only screen and (min-width: 1080px) {
  .bm-burger-button {
    display: none;
  }
}
